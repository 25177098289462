import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react"
import { ResponsiveImageFragment } from "../../types.graphql"
import { twMerge } from "tailwind-merge"

type Props = Partial<ResponsiveImageFragment> & {
  objectFit?: "cover" | "contain"
  objectPosition?:
  | "top"
  | "right-top"
  | "right"
  | "right-bottom"
  | "bottom"
  | "left-bottom"
  | "left"
  | "left-top"
  | "center"
  className?: string
  children?: ReactNode
  fadeOnLoad?: boolean
  alt?: string
  max?: boolean
  aspect?: number | boolean
  onLoad?: () => void
}

export function ResponsiveImage(props: Props) {
  const imgRef = useRef<HTMLImageElement>(null!)
  const [loaded, setLoaded] = useState(false)

  if (env.client) {
    useLayoutEffect(() => {
      // Update the 'sizes' attribute ASAP
      const el = imgRef.current
      if (!el) return
      el.setAttribute("sizes", "200px")
      const ro = new ResizeObserver((entries) => {
        const size = Math.ceil(entries[0].contentRect.width / 40) * 40
        el.setAttribute("sizes", size + "px")
      })
      ro.observe(el)
      return () => ro.disconnect()
    }, [props.mediaItemUrl])
  }

  useEffect(() => {
    if (props.fadeOnLoad === false) return
    let destroyed = false
    const img = imgRef.current
    if (img) {
      if (!img.complete) {
        setLoaded(false)
        img.addEventListener("load", () => {
          if (destroyed) return
          setLoaded(true)
          if (props.onLoad) props.onLoad()
        })
      } else {
        setLoaded(true)
        if (props.onLoad) props.onLoad()
      }
    }
    return () => {
      destroyed = true
    }
  }, [props.mediaItemUrl])

  const aspect =
    props.aspect === false
      ? "unset"
      : typeof props.aspect === "number"
        ? String(props.aspect)
        : props.mediaDetails?.width && props.mediaDetails?.height
          ? props.mediaDetails.width / props.mediaDetails.height
          : ""

  if (!props.mediaItemUrl && !props.srcSet) return null

  return (
    <div
      className={twMerge(
        props.max ? "absolute top-0 left-0 !w-full !h-full" : "aspect-[--aspectRatio]",
        props.className,
      )}
      style={
        {
          "--aspectRatio": aspect,
        } as any
      }
    >
      <img
        ref={imgRef}
        src={props.mediaItemUrl!}
        srcSet={props.srcSet!}
        alt={props.alt || props.caption || ""}
        className={twMerge(
          "!w-full !h-full",
          props.objectFit === "contain" ? "object-contain" : "object-cover",
          props.objectPosition && `object-${props.objectPosition}`,
          // "transition-opacity duration-200",
          // props.fadeOnLoad === false || loaded ? "opacity-100" : "opacity-0",
        )}
        onLoad={props.onLoad}
      />
      {props.children}
    </div>
  )
}
